@import "~@fontsource/roboto/300.css";
@import "~@fontsource/roboto/400.css";
@import "~@fontsource/roboto/500.css";
@import "~@fontsource/roboto/700.css";


$orangeColor: #ff4b00;
$whiteColor: #ffffff;
$purpleColor: #4e006e;
$blackColor: #000000;
$lavendColor: #ecbfff;

body,
html {
    height: 100%;
    margin: 0;
    overflow: hidden;
}

#root {
    /* The image used */
    background-image: url("images/home_visuel_desktop.jpg");
    /* Full height */
    height: 100%;
    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

h1,
h2,
h3,
h4 {
    font-weight: 600;
}

@font-face {
    src: url("./fonts/Roslindale-DisplayUltra.woff") format("woff");
    font-family: "RoslindaleDisplayUltra";
    font-weight: 100;
}

@font-face {
    src: url("./fonts/Inter-Regular.woff") format("woff"), url("./fonts/Inter-Regular.ttf") format("truetype");
    font-family: "InterRegular";
    font-weight: 100;
}

@media screen and (min-width: 1920px) {
    .cartosRecommendationModal {
        min-height: 75vh !important;
    }
}

.no-mt {
    margin-top: 0px !important;
}

.text-primary {
    color: $orangeColor !important;
}

.text-purple {
    color: $purpleColor !important;
}

.purpleBackGround {
    background-color: $purpleColor !important;
}

.contentTitle {
    color: $purpleColor;
    text-decoration: underline;
    text-decoration-color: $orangeColor;
    font-weight: bold !important;
    margin-top: 1rem !important;
    margin-bottom: 0.5rem !important;
}


/* width and height */

::-webkit-scrollbar {
    width: 7px;
    height: 7px;
}


/* Track */

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(170, 170, 170);
    border-radius: 10px;
}


/* Handle */

::-webkit-scrollbar-thumb {
    background: rgb(170, 170, 170);
    border-radius: 10px;
    opacity: 0.5;
}


/* Authentification */

.signin-box {
    background-color: rgba(255, 255, 255, 0.780);
    border-radius: 5%;
    padding: 2rem;
    color: $purpleColor !important;
    height: auto;
}

.slogan {
    width: 100%;
}

// @media (max-height: 708px) {
//     .slogan-image {
//         width: 50%;
//     }

//     .slogan-paper {
//         margin-top: 0 !important;
//     }

//     .signin-box {
//         margin-top: 32px !important;
//     }
// }

// @media (max-width: 767px) {
//     .slogan-image {
//         width: 78%;
//     }
// }

/* TextField */

.MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
.MuiFormLabel-root.MuiInputLabel-root.Mui-focused {
    color: $orangeColor;
    /* Couleur du label et de la bordure en état "focus" */
    border-color: $orangeColor;
    /* Couleur de la bordure en état "focus" */
    border-width: 2px;
    /* Largeur de la bordure en état "focus" */
}

/* MyCoPhoneInput */
.react-tel-input {
    margin-top: 16px;
    margin-bottom: 8px;
    font-size: 15px;
}

.react-tel-input .form-control:focus {
    border-color: $orangeColor;
    border-width: 2px;
    box-shadow: 0 0 0 0 !important;
}

.react-tel-input .special-label.focus {
    color: $orangeColor;
}


.react-tel-input .form-control {
    padding: 12.5px 14px 10.5px 58px !important;
    font-size: 15px;
}

.react-tel-input :disabled {
    cursor: unset;
    color: rgba(0, 0, 0, 0.38);
}

.react-tel-input input[disabled]:hover {
    border-color: #bdbdbd;
}

.react-tel-input .special-label {
    color: rgba(0, 0, 0, 0.38);
}

.react-tel-input-error {
    margin-left: 10px;
    color: #d32f2f;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
}

/* Button */

button[type="submit"] {
    background-color: $purpleColor;

    &.disabled {
        background-color: darkgrey;
    }

    &:hover {
        background-color: $orangeColor;
    }
}

.myCo-phone-button {
    span {
        margin-right: 2px;
    }
}

.signinButton {
    color: $whiteColor !important;
}

.formationActionsButton {
    text-transform: none !important;
    white-space: nowrap;
    justify-content: left !important;
}


/* Button group */

.btnGroupActions {
    span {
        margin-right: -4px;
    }
}

.MuiButtonGroup-root {
    span {
        margin-right: 0px;
    }

    span:not(:last-of-type) {
        .MuiButtonGroup-grouped {
            border-top-right-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
            border-right-color: transparent !important;
        }
    }

    span:not(:first-of-type) {
        .MuiButtonGroup-grouped {
            border-top-left-radius: 0 !important;
            border-bottom-left-radius: 0 !important;
            margin-left: -1px !important;
        }
    }
}


/* Sidenav */

a.sidenavLink {
    text-decoration: none !important;
    color: $blackColor !important;

    &.active div,
    &.active div:hover {
        background-color: $orangeColor !important;
        color: $whiteColor !important;

        svg {
            color: $whiteColor !important;
        }
    }

    div:hover {
        background-color: $lavendColor !important;
        color: $blackColor !important;
    }

    svg {
        color: $purpleColor !important;
    }
}

#email-helper-text:not(.access-form-email-field #email-helper-text),
#password-helper-text,
#useremail-helper-text,
#passwordConfirmation-helper-text {
    margin-left: 10px;
}


/* Form */

.customHelperText {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    text-align: left;
    margin-top: 4px;
    margin-right: 14px;
    margin-bottom: 0;
    margin-left: 14px;
    color: #d32f2f;
}


/* Table */

.MuiTableRow-root:nth-child(even) {
    background: #f7f7f7;
}

.MuiTableRow-root:not(.no-hover):hover {
    background: #f2f2f2 !important;
}

.tableInput {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.v-align-top {
    vertical-align: top !important;
}


/* Popover */

.MuiPaper-root.MuiPopover-paper {
    box-shadow: 0px 1px 4px 1px $orangeColor !important;
}


/* Menu item avatar on select input*/

.MuiAvatar-root.selectMenuItemAvatar {
    width: 40px !important;
    height: 30px !important;
}

.MuiAvatar-root.selectMenuItemAvatarXs {
    width: 25px !important;
    height: 20px !important;
}


/* Custom classes */

.no-py {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.mailto-link {
    color: inherit !important;
    text-decoration: none !important;
}


/* Custom list subheader styles */

.MuiListSubheader-root.CpwlSummaryListSubheader {
    line-height: 24px;
    margin-top: 10px;
    color: $orangeColor;
}


/* Center div text vertically */

.center-y {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
}


/* Accordion */

.conventionAccordion {
    box-shadow: none !important;

    .MuiButtonBase-root-MuiAccordionSummary-root {
        padding: 0 !important;

        div {
            margin: 0 !important;
        }
    }

    .MuiButtonBase-root-MuiAccordionSummary-root {
        padding: 0 !important;
    }
}

.MuiAccordionSummary-content.Mui-expanded {
    margin: 0 !important;
}


/* MyCoResetInput */

.MyCoResetInput {
    position: absolute;
    right: 15px;

    .MyCoResetInput__IconButton svg {
        display: none;
        font-size: 20px;
    }
}

.MuiOutlinedInput-root:hover {
    .MyCoResetInput__IconButton svg {
        display: block;
    }
}


/* Customer */

#menu-interlocutor_roles {
    .MuiList-root {
        max-height: 250px;
    }
}


/* Formation */

table.formation-list-table thead tr th:nth-child(1) {
    min-width: 73px;
}

/* Input */

/*fieldset.MuiOutlinedInput-notchedOutline:hover {
    border-color: $orangeColor !important;
    border-width: 2px;
}
*/

/* Table */

.myco-table {
    tbody {
        tr {
            td:not(.myco-table-td-loading) {
                vertical-align: top;
            }
        }
    }
}

.bordered-table {

    thead,
    tbody {
        tr {

            td,
            th {
                border: 1px solid rgba(224, 224, 224, 1);
            }
        }
    }
}

.mini-table {

    thead,
    tbody {
        tr {

            td,
            th {
                padding: 4px;
                font-size: small;
            }
        }
    }
}

.table-product-and-thematics {

    thead,
    tbody {
        tr {

            td,
            th {
                padding: 4px 6px;
            }
        }
    }
}


/* MyCoDateInput */

.MyCoDateInputBox {
    position: relative;
    user-select: none;

    .clearIconButton {
        position: absolute;
        top: 50%;
        right: 40px;
        transform: translateY(-50%);

        &.margin-undefined {
            top: calc(50% + 4px);
        }

        &.onError {
            top: calc(37% + 4px);
        }
    }

    .clearIconButton2 {
        position: absolute;
        top: 25%;
        right: 0%;

        //    transform: translateY(-50%);
        &.margin-undefined {
            top: calc(25% + 4px);
        }

        &.margin-none {
            top: 0% !important;
        }

        &.margin-custom {
            top: 10% !important;
        }

        &.onError {
            top: calc(10% + 6px);
        }
    }
}

.myCoDateInputPoper {
    z-index: 3 !important;
}

.with-date-picker div.MuiPaper-root.MuiPaper-elevation {
    overflow-y: unset !important;
}


/* Charts */

.bar-series-chart {
    min-height: 380px;

    #top-left {
        width: 0 !important;
    }

    #center-container {
        min-height: 159px;
    }

    .detail-button {
        top: 31px;
    }
}


/* Modal */

.myco-modal-fullheight {
    div {
        div.MuiPaper-root.MuiDialog-paper {
            height: 95% !important;
        }
    }
}


/* Tabs */

.MuiButtonBase-root.MuiTab-root {
    color: #756860;
    background-color: #f2f2f2;
    border-left: solid 1px lightgrey;

    &.Mui-selected {
        color: $purpleColor;
        background-color: rgb(78, 0, 110, 0.2);
        // -webkit-border-top-left-radius: 15px;
        // -webkit-border-top-right-radius: 15px;
        // -moz-border-radius-topleft: 15px;
        // -moz-border-radius-topright: 15px;
        // border-top-left-radius: 15px;
        // border-top-right-radius: 15px;
    }
}

.MuiTabs-indicator {
    background-color: $purpleColor !important;
}

.MuiTabPanel-root {
    padding: 0px !important;
    padding-top: 8px !important;
}


/* Formation general info */

.formationGeneralInfoContainer {
    margin-top: 5px !important;

    .formationGeneralGrid {
        border: 1px solid $purpleColor;
        border-radius: 5px;
        background-color: #f0f0f0 !important;
        padding: 1rem 1rem;
        text-align: "center";
    }
}

@media only screen and (min-device-width: 600px) and (max-device-width: 1000px) and (orientation: landscape) {
    .formationGeneralInfoContainer {
        flex-direction: column !important;
    }
}


/* Grid custom classes */

.grid-item-no-mt .MuiFormControl-root.MuiTextField-root {
    margin-top: 0;
}